



  import { Validator } from '@/constant/Mixins';
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Mixins, Watch } from 'vue-property-decorator';

  @Component({
    name: 'Currency',
    components: {},
    mixins: [],
  })
  export default class Currency extends Mixins(Validator) {
    public price = 0;

    public CurrencyActive = false;

    public get Currency(): string {
      if (this.CurrencyActive) return this.price.toString();
      else return this.formatCurrency(this.price, true);
    }

    public set Currency(v: string) {
      let x = this.Currency.replaceAll(new RegExp('[^0-9]', 'g'), '');
      this.price = Number(x);
      this.$emit('input', this.price);
    }

    public created(): void {
      // on componenet created
    }
    public mounted(): void {
      // on componenet mounted
    }
  }
